import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './RequesterUnauthenticated.css';

import { AppStoreLink, Navbar, Footer } from '../../components';
import { FeatureList, DesktopPersonaSelectionViewer, PhoneThreadViewer, UseCaseList } from '../../components/requester';
import OrderButton from '../../components/ordering/OrderButton';
import PersonasList from '../../components/requester/PersonasList';

export default function RequesterUnauthenticated(props) {
    const navigate = useNavigate();

    const [userProfileDropdownState, setUserProfileDropdownState] = useState(false);

    const handleBasePageClick = () => {
        setUserProfileDropdownState(false);
    };

    const activateAuthModal = () => {
        setUserProfileDropdownState(false);
        document.body.style.overflow = 'hidden';
        props.activateAuth("SIGN_IN");
    };

    const toggleUserProfileDropdownState = (e) => {
        e.stopPropagation();
        setUserProfileDropdownState(state => !state);
    };

    const handleAnnouncementClick = () => {
        navigate('/blog/the-risks-that-every-startup-faces')
    };

    return (
        <div className="page-base gray" onClick={handleBasePageClick}>
            <Navbar type="REQUESTER" userProfileDropdownState={userProfileDropdownState} toggleUserProfileDropdownState={toggleUserProfileDropdownState} activateAuthModal={activateAuthModal} />
            <section className="content-background green-gradient">
                <div className="content-section">
                    <div className="hero-section">
                        <div className="hero-announcement-text-action-section">
                            <div className="hero-announcement-text-section">
                                <div className="hero-announcement-section">
                                    <p className="hero-announcement-text">What are the biggest risks to your startup?</p>
                                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-point-filled" width="10" height="10" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#FFFFFFF" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                        <path d="M12 7a5 5 0 1 1 -4.995 5.217l-.005 -.217l.005 -.217a5 5 0 0 1 4.995 -4.783z" strokeWidth="0" fill="#FFFFFF" />
                                    </svg>
                                    <div className="hero-announcement-action-section" onClick={handleAnnouncementClick}>
                                        <p className="hero-announcement-text">Read More</p>
                                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-chevron-right" width="15" height="15" viewBox="0 0 24 24" strokeWidth="3" stroke="#FFFFFF" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                            <path d="M9 6l6 6l-6 6" />
                                        </svg>
                                    </div>
                                </div>
                                <div className="hero-text-section">
                                    <h1 className="hero-primary-text">We connect startups and users</h1>
                                    <p className="hero-secondary-text">Accelerate your startup by instantly connecting with your target audiences for user research, feedback, customer acquisition, and beta testing.</p>
                                </div>
                            </div>
                            <OrderButton onClick={() => props.activateOrdering(null, 0)} />
                        </div>
                        <div className="hero-image-section">
                            <DesktopPersonaSelectionViewer />
                            <PhoneThreadViewer />
                        </div>
                    </div>
                </div>
            </section>
            <section className="content-background white">
                <div className="content-section">
                    <FeatureList />
                </div>
            </section>
            <section className="content-background white">
                <div className="content-section">
                    <div className="use-case-section">
                        <div className="use-case-section-title-arrows">
                            <h2 className="use-case-section-title-text">For everything your startup needs</h2>
                        </div>
                        <UseCaseList />
                    </div>
                </div>
            </section>
            <section className="content-background white">
                <div className="content-section">
                    <div className="personas-list-section">
                        <div className="use-case-section-title-arrows">
                            <h2 className="use-case-section-title-text">Popular personas</h2>
                        </div>
                        <PersonasList activateOrdering={(order) => props.activateOrdering(order, 2)} />
                    </div>
                </div>
            </section>
            <section className="content-background white">
                <div className="content-section">
                    <div className="call-to-action-section">
                        <h2 className="use-case-section-title-text">Iterate faster with Userverse</h2>
                        <div className="get-started-button" onClick={activateAuthModal}>Get started</div>
                    </div>
                </div>
            </section>
            <Footer />
        </div>
    );
}