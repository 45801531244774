import React, { useState } from 'react';
import './RequesterAuthenticated.css';

import { RequesterBase } from '../../components/requester';
import RequesterAuthenticatedNavbar from '../../components/Navbar/RequesterAuthenticatedNavbar/RequesterAuthenticatedNavbar';

export default function RequesterAuthenticatedPage(props) {
    const [userProfileDropdownState, setUserProfileDropdownState] = useState(false);

    const handleBasePageClick = () => {
        setUserProfileDropdownState(false);
    };

    const toggleUserProfileDropdownState = (e) => {
        e.stopPropagation();
        setUserProfileDropdownState(state => !state);
    };

    return (
        <div className="page-base" onClick={handleBasePageClick}>
            <RequesterAuthenticatedNavbar orderingState={props.orderingState} activateOrdering={() => props.activateOrdering(null, 0)} userProfileDropdownState={userProfileDropdownState} toggleUserProfileDropdownState={toggleUserProfileDropdownState} />
            <main className="requester-authenticated-base">
                <RequesterBase activateOrdering={props.activateOrdering} orderingState={props.orderingState} />
            </main>
        </div>
    );
}