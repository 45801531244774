import React, { useState } from 'react';

import { Navbar, Footer }  from '../../components';
import { AuthController } from '../../components/auth';

export default function TermsOfService(props) {
    const [userProfileDropdownState, setUserProfileDropdownState] = useState(false);

    const [authState, setAuthState] = useState("UNSET");

    const handleBasePageClick = () => {
        setUserProfileDropdownState(false);
    };

    const activateAuthModal = (authState) => {
        setAuthState(authState);
        setUserProfileDropdownState(false);
    };

    const deactivateAuthModal = () => {
        setAuthState("UNSET");
    };

    const toggleUserProfileDropdownState = (e) => {
        e.stopPropagation();
        setUserProfileDropdownState(state => !state);
    };

    return (
        <>
            <AuthController authState={authState} deactivate={deactivateAuthModal} />
            <div className="page-base" onClick={handleBasePageClick}>
                <Navbar type="REQUESTER" userProfileDropdownState={userProfileDropdownState} toggleUserProfileDropdownState={toggleUserProfileDropdownState} activateAuthModal={activateAuthModal} />
                <main>
                    <div className="content-section">
                        <div className="privacy-policy">
                            <div className="privacy-policy-header">
                                <h1 className="black-header-1-text">Terms of Service</h1>
                                <p className="black-regular-5-text">Effective Date: April 15, 2024</p>
                            </div>

                            <div className="privacy-policy-table-of-contents">
                                <h2 className="black-header-3-text">Table of Contents</h2>
                                <p className="black-regular-5-text"><a href="#agreement-to-terms">1. Agreement to Terms</a></p>
                                <p className="black-regular-5-text"><a href="#userverse-services">2. Userverse Services</a></p>
                                <p className="black-regular-5-text"><a href="#accounts">3. Accounts</a></p>
                                <p className="black-regular-5-text"><a href="#user-contributions">4. User Contributions</a></p>
                                <p className="black-regular-5-text"><a href="#payment-terms">5. Payment Terms</a></p>
                                <p className="black-regular-5-text"><a href="#prohibited-activities">6. Prohibited Activities</a></p>
                                <p className="black-regular-5-text"><a href="#modifications-and-interruptions">7. Modifications and Interruptions</a></p>
                                <p className="black-regular-5-text"><a href="#governing-law">8. Governing Law</a></p>
                                <p className="black-regular-5-text"><a href="#contact-us">9. Contact Us</a></p>
                            </div>

                            <section id="agreement-to-terms" className="privacy-policy-section">
                                <h3 className="black-header-3-text">1. Agreement to Terms</h3>
                                <p className="black-regular-5-text">By accessing or using our marketplace, services, mobile application, or any other feature provided by Userverse Inc. (collectively, "Services"), you agree that you have read, understood, and agree to be bound by these terms. If you do not agree with all of these terms, do not access or use our Services.</p>
                            </section>

                            <section id="userverse-services" className="privacy-policy-section">
                                <h3 className="black-header-3-text">2. Userverse Services</h3>
                                <p className="black-regular-5-text">Userverse Inc. operates a marketplace where "Requesters" (companies of various sizes) can request user research, beta testing, product feedback, messaging testing, price testing, and more from specified personas. We match these requests with "Responders" (individuals who provide these services). Responders are compensated for their contributions, and Requesters are charged for each completed response.</p>
                            </section>

                            <section id="accounts" className="privacy-policy-section">
                                <h3 className="black-header-3-text">3. Accounts</h3>
                                <p className="black-regular-5-text">a. <strong>Account Creation:</strong> To use our Services, you must register for an account. You agree to provide accurate, current, and complete information during the registration process and to update such information to keep it accurate, current, and complete. You must be at least 18 years of age to create an account and use the Services. By creating an account, you represent and warrant that you are at least 18 years old and have the legal capacity and authority to enter into a contract.</p>
                                <p className="black-regular-5-text">b. <strong>Account Responsibilities:</strong> You are responsible for maintaining the confidentiality of your account login information and are fully responsible for all activities that occur under your account. You must immediately notify Userverse Inc. of any unauthorized use, or suspected unauthorized use, of your account or any other breach of security.</p>
                            </section>

                            <section id="user-contributions" className="privacy-policy-section">
                                <h3 className="black-header-3-text">4. User Contributions</h3>
                                <p className="black-regular-5-text">a. <strong>Responder Contributions:</strong> Responders may submit content, including feedback, experiences, and personal persona details. By submitting such content, you grant Userverse Inc. a worldwide, perpetual, irrevocable, royalty-free license to use, modify, perform, display, reproduce, and distribute the content in connection with operating our Services.</p>
                                <p className="black-regular-5-text">b. <strong>Content Accuracy:</strong> Responders are responsible for providing accurate and current information about their personas. You may update or delete your persona information at any time.</p>
                            </section>

                            <section id="payment-terms" className="privacy-policy-section">
                                <h3 className="black-header-3-text">5. Payment Terms</h3>
                                <p className="black-regular-5-text">a. <strong>For Requesters:</strong> You agree to pay the fees agreed upon for responses received from Responders.</p>
                                <p className="black-regular-5-text">b. <strong>For Responders:</strong> You will be compensated per the terms agreed upon before accepting a request. Earnings can be transferred to your specified bank account, subject to the terms of our payment processing partner.</p>
                            </section>

                            <section id="prohibited-activities" className="privacy-policy-section">
                                <h3 className="black-header-3-text">6. Prohibited Activities</h3>
                                <p className="black-regular-5-text">You may not access or use the Services for any purpose other than that for which we make the Services available. Prohibited activities include, but are not limited to, soliciting others to perform or participate in any unlawful acts, infringing on the privacy rights of others, harassing, abusing, or harming another person, using the Services in a misleading or fraudulent manner, and interfering with or circumventing the features of the Services or any related websites.</p>
                            </section>

                            <section id="modifications-and-interruptions" className="privacy-policy-section">
                                <h3 className="black-header-3-text">7. Modifications and Interruptions</h3>
                                <p className="black-regular-5-text">We reserve the right to change, modify, or remove the contents of the Services at any time or for any reason at our sole discretion without notice. We also reserve the right to modify or discontinue all or part of the Services without notice at any time.</p>
                            </section>

                            <section id="governing-law" className="privacy-policy-section">
                                <h3 className="black-header-3-text">8. Governing Law</h3>
                                <p className="black-regular-5-text">These Terms shall be governed by and defined following the laws of the United States. Userverse Inc. and yourself irrevocably consent that the courts of the United States shall have exclusive jurisdiction to resolve any dispute which may arise in connection with these terms.</p>
                            </section>

                            <section id="contact-us" className="privacy-policy-section">
                                <h3 className="black-header-3-text">9. Contact Us</h3>
                                <p className="black-regular-5-text">For any questions or concerns regarding these Terms, please contact us at: userverse.team@gmail.com</p>
                            </section>
                        </div>
                    </div>
                </main>
                <Footer />
            </div>
        </>
    );
}