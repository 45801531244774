import React from 'react';
import './OrderButton.css';

export default function OrderButton(props) {
    return (
        <div className="order-button" onClick={(e) => props.onClick(e)}>
            <div className="order-button-inputs">
                <div className="order-button-input">
                    <p className="black-header-5-text">10 users</p>
                </div>
                <div className="order-button-divider" />
                <div className="order-button-input">
                    <p className="black-header-5-text">Anyone</p>
                </div>
                <div className="order-button-divider" />
                <div className="order-button-input">
                    <p className="light-gray-regular-5-text">Do you meal plan? Why or why not?</p>
                </div>
            </div>
            <div className="order-button-submit">
                <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-arrow-right" width="18" height="18" viewBox="0 0 24 24" strokeWidth="3" stroke="#FFFFFF" fill="none" strokeLinecap="round" strokeLinejoin="round">
                    <path d="M5 12l14 0"></path>
                    <path d="M13 18l6 -6"></path>
                    <path d="M13 6l6 6"></path>
                </svg>
            </div>
        </div>
    );
}