import React, { useEffect, useState } from 'react';

import Modal from '../Modal';

import { useAuth, useInput } from '../../hooks';

export default function VerificationCode(props) {
  const auth = useAuth();

    const { value: code, bind: bindCode, reset: resetCode } = useInput("");

    const [codeError, setCodeError] = useState(null);

    const [loading, setLoading] = useState(false);

    useEffect(() => {
      setCodeError(null);
    }, [code]);

    const handleSubmit = () => {
        var validRegex = /^[0-9]+$/;
        if (code === "") {
          setCodeError("Code is required");
          return;
        } else if (!code.match(validRegex)) {
          setCodeError("Not a valid code");
          return;
        }
        setLoading(true);
        auth.handleConfirmSignIn(code, props.shouldUpdateAccount).then(() => {
          setLoading(false);
          resetCode();
          props.next();
        }).catch((error) => {
          setCodeError(error);
          setLoading(false);
        });
    };

    const handleEnter = (e) => {
      if (e.key === 'Enter') {
          e.preventDefault();
          handleSubmit();
      }
    };

    const exit = (e) => {
      if (e === undefined || e.target === e.currentTarget) {
        setLoading(false);
        resetCode();
        props.exit(e);
      }
    };

    return (
        <Modal 
          detail={
            <div className="auth-modal-base">
              <h2 className="black-regular-5-text">We've sent a code to your email. Please enter it here.</h2>
              <div className="form-text-fields">
                  <div className="form-text-field">
                    <input className={codeError === null ? "text-input-box" : "red-text-input-box"} type="text" placeholder="Your code" onKeyDown={(e) => handleEnter(e)} {...bindCode} />
                    {
                      codeError !== null && (
                        <div className="auth-modal-error-message">
                          <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-alert-circle" width="16" height="16" viewBox="0 0 24 24" strokeWidth="2" stroke="#C13515" fill="none" strokeLinecap="round" strokeLinejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                            <path d="M3 12a9 9 0 1 0 18 0a9 9 0 0 0 -18 0"></path>
                            <path d="M12 8v4"></path>
                            <path d="M12 16h.01"></path>
                          </svg>
                          <p className="red-regular-5-text">{codeError}</p>
                        </div>
                      )
                    }
                  </div>
                </div>
            </div>
          }
          title={"Two-factor authentication"}
          action={"Continue"}
          handleAction={handleSubmit}
          loading={loading}
          exit={exit} />
    );
}