import React, { useState } from 'react';
import './PaymentMethodForm.css';
import { useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js';

const PaymentMethodForm = (props) => {
  const stripe = useStripe();
  const elements = useElements();

  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements || !props.clientSecret) return;

    setIsLoading(true);

    stripe.confirmSetup({
      elements,
      confirmParams: {
        return_url: 'https://user-verse.com'
      },
      redirect: 'if_required'
    }).then((response) => {
      setIsLoading(false);
      props.onSuccess();
    }).catch((error) => {
      console.log(error);
      setIsLoading(false);
      setErrorMessage("Unable to add your payment method")
    });
  };

  return (
    <form onSubmit={handleSubmit} className="payment-method-form">
      {
        props.clientSecret && (
          <>
            <PaymentElement />
            {
              isLoading ? (
                <div className="full-width-button disabled">
                  <div className="loading-circle" />
                </div>
              ) : (
                <button disabled={!stripe || isLoading} type="submit" className="payment-method-submit-button" onClick={(e) => handleSubmit(e)}>Save payment method</button>
              )
            }
          </>
        )
      }
    </form>
  );
};

export default PaymentMethodForm;