import React, { useState, useContext, createContext } from 'react';
import { confirmSignIn, fetchAuthSession, resendSignUpCode, signIn, signOut, signUp } from '@aws-amplify/auth';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';

const AuthContext = createContext();

export function ProvideAuth({ children }) {
	const auth = useProvideAuth();
	return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
}

export const useAuth = () => {
	return useContext(AuthContext);
}

export function useProvideAuth() {
	const [user, setUser] = useState(null);

	const getUser = async (shouldUpdateAccount) => {
		try {
			const response = await fetchAuthSession();
		
			const userUUID = response?.userSub || "";
			const idToken = response?.tokens?.idToken?.toString() || "";
		
			if (!userUUID || !idToken) {
			setUser(false);
			return;
			}

			axios.defaults.headers.common['Authorization'] = idToken;
		
			const params = { user_uuid: userUUID };
		
			if (shouldUpdateAccount) {
				await updateAccount(params);
			} else {
				await updateSession(params);
			}
		
			const userResponse = await getUserData(params);
			userResponse.auth_token = idToken;
			setUser(userResponse);
			return userResponse;
		} catch (error) {
			setUser(false);
			console.log(error);
		}
	  };
	  
	const updateAccount = (params) => {
		return axios.put('/user/account', params);
	};

	const updateSession = async (params) => {
		try {
			await axios.put('/user/session', {
				...params, user_type: "REQUESTER"
			});
		} catch (error) {
		  	console.log('updateSession failed, proceeding:', error);
		}
	};
		
	const getUserData = (params) => {
		return axios.get('/user', { params })
			.then(response => response.data);
	};

	const handleSignIn = (username) => {
		return new Promise((resolve, reject) => {
			signIn({
				username,
				options: {
					authFlowType: 'CUSTOM_WITHOUT_SRP'
				}
			}).then(response => {
				resolve();
			}).catch(error => {
				switch (error.message) {
					case "User does not exist.":
						reject("Email not found. Please create an account first.");
						break;
					default:
						reject(error.message);
				}
			});
		});
	};

	const handleSignUp = (firstName, lastName, email) => {
		return new Promise((resolve, reject) => {
			signUp({
				username: uuidv4(),
				password: getRandomString(30),
				options: {
					userAttributes: {
						name: firstName + " " + lastName,
						email: email,
						'custom:first_name': firstName,
						'custom:last_name': lastName,
					}
				}
			}).then(() => {
				resolve();
			}).catch((error) => {
				switch(error.message) {
					case "PreSignUp failed with error This email already exists..":
						reject("This username is taken. Please try another.");
						break;
					default:
						reject(error);
				}
			});
		});
	};

	const handleConfirmSignIn = (challengeResponse, shouldUpdateAccount) => {
		return new Promise((resolve, reject) => {
			confirmSignIn({ challengeResponse })
				.then((response) => {
					if (response['isSignedIn']) {
						getUser(shouldUpdateAccount).then(() => {
							resolve();
						})
					} else {
						reject("Your code was invalid. Please try again.");
					}
				})
				.catch((error) => {
					console.log(error);
					switch (error.code) {
						case "NotAuthorizedException":
							reject("This code has expired. Please try again.");
							break;
						default:
							reject(error);
					}
				});
		});
	};

	const handleResendCode = (username) => {
		return new Promise((resolve, reject) => {
			resendSignUpCode(username).then(() => {
				resolve();
			}).catch((error) => {
				reject(error);
			});
		});
	};

	const handleSignOut = () => {
		return new Promise((resolve, reject) => {
			axios.defaults.headers.common['Authorization'] = '';
			signOut().then(() => {
				setUser(null);
				resolve();
			}).catch((error) => {
				reject(error);
			});
		});
	};

	return {
		user,
		handleSignIn,
		handleSignUp,
		handleSignOut,
		handleResendCode,
		handleConfirmSignIn,
		getUser
	};
}

function getRandomString(bytes) {
	const randomValues = new Uint8Array(bytes);
	window.crypto.getRandomValues(randomValues);
	return Array.from(randomValues).map(intToHex).join('');
}

function intToHex(nr) {
	return nr.toString(16).padStart(2, '0');
}