import React from 'react';
import './PersonasList.css';

export default function PersonasList(props) {
    const handleClick = (traits) => {
        props.activateOrdering({
            "requested_traits": traits,
            "requested_quantity": 10,
            "request_payload": ""
        });
    };

    return (
        <div className="FullWidthCarousel">
            <div className="FullWidthCarousel__track">
                <div className="FullWidthCarousel__spacer" />
                <div className="PersonaCard">
                    <div className="PersonaCard__image color1" />
                    <div className="PersonaCard__content">
                        <header className="PersonaCard__header">
                            <h1 className="PersonaCard__title">Software Engineer</h1>
                        </header>
                        <div className="PersonaCard__body">
                            <p className="PersonaCard__text">From $0.90 / response</p>
                        </div>
                    </div>
                    <div className="PersonaCard__button" onClick={() => handleClick({"Occupation": ["Software Engineer"]})}>
                        <p className="PersonaCard__boldText">10 customers</p>
                        <div className="PersonaCard__submit">
                            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-arrow-right" width="18" height="18" viewBox="0 0 24 24" strokeWidth="3" stroke="#FFFFFF" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                <path d="M5 12l14 0"></path>
                                <path d="M13 18l6 -6"></path>
                                <path d="M13 6l6 6"></path>
                            </svg>
                        </div>
                    </div>
                </div>
                <div className="PersonaCard">
                    <div className="PersonaCard__image color2" />
                    <div className="PersonaCard__content">
                        <header className="PersonaCard__header">
                            <h1 className="PersonaCard__title">Content Creator</h1>
                        </header>
                        <div className="PersonaCard__body">
                            <p className="PersonaCard__text">From $0.90 / response</p>
                        </div>
                    </div>
                    <div className="PersonaCard__button" onClick={() => handleClick({"Occupation": ["Content Creator"]})}>
                        <p className="PersonaCard__boldText">10 customers</p>
                        <div className="PersonaCard__submit">
                            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-arrow-right" width="18" height="18" viewBox="0 0 24 24" strokeWidth="3" stroke="#FFFFFF" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                <path d="M5 12l14 0"></path>
                                <path d="M13 18l6 -6"></path>
                                <path d="M13 6l6 6"></path>
                            </svg>
                        </div>
                    </div>
                </div>
                <div className="PersonaCard">
                    <div className="PersonaCard__image color3" />
                    <div className="PersonaCard__content">
                        <header className="PersonaCard__header">
                            <h1 className="PersonaCard__title">Entrepreneur</h1>
                        </header>
                        <div className="PersonaCard__body">
                            <p className="PersonaCard__text">From $0.90 / response</p>
                        </div>
                    </div>
                    <div className="PersonaCard__button" onClick={() => handleClick({"Occupation": ["Entrepreneur"]})}>
                        <p className="PersonaCard__boldText">10 customers</p>
                        <div className="PersonaCard__submit">
                            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-arrow-right" width="18" height="18" viewBox="0 0 24 24" strokeWidth="3" stroke="#FFFFFF" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                <path d="M5 12l14 0"></path>
                                <path d="M13 18l6 -6"></path>
                                <path d="M13 6l6 6"></path>
                            </svg>
                        </div>
                    </div>
                </div>
                <div className="PersonaCard">
                    <div className="PersonaCard__image color4" />
                    <div className="PersonaCard__content">
                        <header className="PersonaCard__header">
                            <h1 className="PersonaCard__title">Job Seeker</h1>
                        </header>
                        <div className="PersonaCard__body">
                            <p className="PersonaCard__text">From $0.90 / response</p>
                        </div>
                    </div>
                    <div className="PersonaCard__button" onClick={() => handleClick({"Occupation": ["Job Seeker"]})}>
                        <p className="PersonaCard__boldText">10 customers</p>
                        <div className="PersonaCard__submit">
                            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-arrow-right" width="18" height="18" viewBox="0 0 24 24" strokeWidth="3" stroke="#FFFFFF" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                <path d="M5 12l14 0"></path>
                                <path d="M13 18l6 -6"></path>
                                <path d="M13 6l6 6"></path>
                            </svg>
                        </div>
                    </div>
                </div>
                <div className="PersonaCard">
                    <div className="PersonaCard__image color5" />
                    <div className="PersonaCard__content">
                        <header className="PersonaCard__header">
                            <h1 className="PersonaCard__title">Homeowner</h1>
                        </header>
                        <div className="PersonaCard__body">
                            <p className="PersonaCard__text">From $0.90 / response</p>
                        </div>
                    </div>
                    <div className="PersonaCard__button" onClick={() => handleClick({"Occupation": ["Homeowner"]})}>
                        <p className="PersonaCard__boldText">10 customers</p>
                        <div className="PersonaCard__submit">
                            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-arrow-right" width="18" height="18" viewBox="0 0 24 24" strokeWidth="3" stroke="#FFFFFF" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                <path d="M5 12l14 0"></path>
                                <path d="M13 18l6 -6"></path>
                                <path d="M13 6l6 6"></path>
                            </svg>
                        </div>
                    </div>
                </div>
                <div className="PersonaCard">
                    <div className="PersonaCard__image color6" />
                    <div className="PersonaCard__content">
                        <header className="PersonaCard__header">
                            <h1 className="PersonaCard__title">Project Manager</h1>
                        </header>
                        <div className="PersonaCard__body">
                            <p className="PersonaCard__text">From $0.90 / response</p>
                        </div>
                    </div>
                    <div className="PersonaCard__button" onClick={() => handleClick({"Occupation": ["Project Manager"]})}>
                        <p className="PersonaCard__boldText">10 customers</p>
                        <div className="PersonaCard__submit">
                            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-arrow-right" width="18" height="18" viewBox="0 0 24 24" strokeWidth="3" stroke="#FFFFFF" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                <path d="M5 12l14 0"></path>
                                <path d="M13 18l6 -6"></path>
                                <path d="M13 6l6 6"></path>
                            </svg>
                        </div>
                    </div>
                </div>
                <div className="FullWidthCarousel__spacer" />
            </div>
        </div>
    );
}